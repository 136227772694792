<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';

import firebaseConnect from './environment/firebaseConnect'

export default {
  name: "App",
  data: function(){
    return {
      path: this.$route.path
    }
  },
  created: function(){
    firebaseConnect.logEvent(firebaseConnect.analytics, 'pageVisit@'+this.path)
  }
};
</script>

<style>
@import "/css/color.css";
@import "/css/modal.css";

@font-face {
  font-family: DBAdmanX;
  src: url("/fonts/DB_Adman_X.ttf") format("truetype"),
    url("/fonts/DB_Adman_X_UILi.ttf") format("truetype"),
    url("/fonts/DB_Adman_X_UILi_IT.ttf") format("truetype"),
    url("/fonts/DB_Adman_X_Li.ttf") format("truetype"),
    url("/fonts/DB_Adman_X_Li_It.ttf") format("truetype"),
    url("/fonts/DB_Adman_X_It.ttf") format("truetype"),
    url("/fonts/DB_Adman_X_Bd.ttf") format("truetype"),
    url("/fonts/DB_Adman_X_Bd_It.ttf") format("truetype");
}

@font-face {
  font-family: DBHelvethaicaX;
  src: url("/fonts/DB_Helvethaica_X_.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Bd_Cond_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Bd_Cond.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Bd_Ext_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Bd_Ext.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Bd_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Bd.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Blk_Cond_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Blk_Cond.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Blk_Ext_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Blk_Ext.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Blk_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Blk.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Cond_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Cond.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Ext_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Ext.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Li_Cond_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Li_Cond.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Li_Ext_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Li_Ext_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Li_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Li.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Med_Cond_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Med_Cond.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Med_Ext_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Med_Ext.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Med_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Med.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Thin_Cond_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Thin_Cond.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Thin_Ext_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Thin_Ext.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Thin_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_Thin.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_UlLi_Cond_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_UlLi_Cond.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_UlLi_Ext_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_UlLi_Ext.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_UlLi_It.ttf") format("truetype"),
    url("/fonts/DB_Helvethaica_X_UlLi.ttf") format("truetype");
}

@font-face {
  font-family: DBHelvethaicaXBdExt;
  src: url("/fonts/DB_Helvethaica_X_Bd_Ext.ttf") format("truetype");
}

@font-face {
  font-family: DBHelvethaicaXMedCond;
  src: url("/fonts/DB_Helvethaica_X_Med_Cond.ttf") format("truetype");
}

@media only screen and (min-width: 821px) {
  html body {
    background-image: url("~@/assets/backgrounds/bg-start.jpg"),
      url("~@/assets/backgrounds/bg-end.jpg"),
      url("~@/assets/backgrounds/bg-repeat.jpg");
    background-repeat: no-repeat, no-repeat, repeat;
    background-position: left top, right top, left top;
    background-size: contain, contain, contain;
    background-attachment: fixed, fixed, fixed;
  }
}

@media only screen and (max-width: 820px) {
  html body {
    background-image: url("~@/assets/backgrounds/bg-start.jpg"),
      url("~@/assets/backgrounds/bg-end.jpg"),
      url("~@/assets/backgrounds/bg-repeat.jpg");
    background-repeat: no-repeat, no-repeat, repeat;
    background-position: left top, right 132px, left top;
    background-size: 128px, 128px, contain;
    background-attachment: fixed, fixed, fixed;
    background-attachment: fixed, fixed, fixed;
  }
}

@media only screen and (min-width: 630px) {
  .footer {
    width: 100%;
    text-align: center;
  }

  .footer .img1 {
    width: 256px;
    height: auto;
    margin-right: 24px;
    vertical-align: middle;
    display: inline;
  }

  .footer .img2 {
    width: 128px;
    height: auto;
    vertical-align: middle;
    display: inline;
  }

  .text-description span {
    display: inline-block;
  }

  .container {
    width: 630px;
  }

  .container2 {
    width: 800px;
  }

  .title-text {
    text-align: center;
  }

  .reward img {
    width: 80px;
    height: auto;
  }

  .reward {
    text-align: end;
  }

    .text-description {
    margin-top: 0px;
    text-align: start;
  }
}

@media only screen and (max-width: 629px) {
  .reward {
    display: none;
  }
  .footer {
    width: 100%;
    text-align: center;
  }

  .footer .img1 {
    width: 100%;
    height: auto;
    margin-right: 16px;
    padding: 16px 64px;
    vertical-align: middle;
  }

  .footer .img2 {
    width: 75%;
    height: auto;
    padding: 16px 64px;
    vertical-align: middle;
  }

  .text-description {
    padding-left: 32px;
    padding-right: 32px;
  }

  .text-description span {
    margin-right: 8px;
  }

  .slogan {
    text-align: right;
    padding-right: 16px;
  }

  .card-text {
    text-align: left;
  }

  .title-text {
    text-align: start;
    margin-left: 100px;
    font-size: 32px;
  }

  
}

#app {
  font-family: DBHelvethaicaX, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 32px;
  font-size: 22px;
}

.logo {
  width: 150px;
  height: auto;
  margin-bottom: 16px;
}

.img-preview {
  cursor: pointer;
  transition: transform 0.2s;
}

.img-preview img {
  /* 46:35 */
  width: 100%;
  height: 180px;
  object-fit: cover;
  border: 16px solid transparent;
  border-image: url("~@/assets/frame.png") 30 round;
  margin-bottom: 8px;
}

.hover-zoom:hover {
  transform: scale(
    1.1
  ); /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.btn-round {
  border-radius: 32px;
  padding: 4px 48px;
  font-size: 24px;
}

.reaction {
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-right-radius: 16px;
  padding: 2px 6px;
  padding-right: 16px;
  font-size: 16px;
  cursor: pointer;
}

.reaction span {
  font-size: 22px;
  font-weight: bold;
}

.reaction-2 {
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
  /*transform: translate(-50%, 0%);*/
  border-bottom-right-radius: 16px;
  padding: 2px 18px;
  font-size: 22px;
  cursor: pointer;
  z-index: 24;
}

.reaction-2 span {
  font-size: 26px;
  font-weight: bold;
}

.relative {
  position: relative;
}

.obj-scaling {
  -webkit-animation: scaling 2.5s linear infinite;
  -moz-animation: scaling 2.5s linear infinite;
  animation: scaling 2.5s linear infinite;
}
@-moz-keyframes scaling {
  0% {
    -moz-transform: scale(0.95);
  }
  50% {
    -moz-transform: scale(1.1);
  }
  100% {
    -moz-transform: scale(0.95);
  }
}
@-webkit-keyframes scaling {
  0% {
    -webkit-transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(0.95);
  }
}
@keyframes scaling {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

.slogan {
  font-family: DBHelvethaicaXBdExt;
}

.btn-back i {
  font-size: 18px;
}

.btn-back span {
  font-size: 24px;
}

.bgtext {
  background-image: url("~@/assets/textbg.png");
  background-size: contain;
  background-repeat: repeat-x;
  padding: 6px 8px;
}
</style>

