<template>
  <div id="image">
    <div class="slogan">
      <a href="/"
        ><img class="logo" alt="Mediastock logo" src="../assets/logo.png"
      /></a>
      <h2 class="slogan title-text">
        คุณเลือกให้ภาพนี้เป็น<br>
        <span class="bgtext">Popular Vote ภาพขวัญใจชาวไทยพีบีเอส</span>
      </h2>
    </div>
    <ImageComp />
  </div>
</template>

<script>
import ImageComp from "../components/ImageComp.vue";

export default {
  name: "ImageView",
  components: {
    ImageComp,
  },
};
</script>