<template>
  <div id="thankyou" class="hello">
    <div id="myModal" ref="myModal" class="modal">
      <div class="modal-content text-end">
        <span id="close" ref="close" v-on:click="closeModal()" class="close"
          >&times;</span
        >
        <div class="text-center">
          <h1 class="text-theme-5 mb-2">
            <span class="bgtext slogan"><b>คุณเคยโหวตภาพนี้ไปแล้ว</b></span>
          </h1>
          <h2 class="text-theme-5">
            แต่ทางเราจะอัพเดทข้อมูลใหม่ที่คุณกรอกมาให้ <br />
            คุณอยากโหวตอีกใช่ไหม?
          </h2>
          <a href="/" class="btn btn-round bg-tpbs-1 text-white mt-3">
            คลิกเลย!
          </a>
        </div>
      </div>
    </div>

    <div id="myModal2" ref="myModal2" class="modal">
      <div class="modal-content text-end">
        <span id="close2" ref="close2" v-on:click="closeModal2()" class="close"
          >&times;</span
        >
        <div class="thankyou">
          <img
            alt="คลิกโหวตที่ภาพเลย !!!"
            class="mb-2"
            src="../assets/thankyou.png"
          />
          <h2 class="text-theme-5">คุณอยากโหวตอีกใช่ไหม?</h2>
          <a href="/" class="btn btn-round bg-tpbs-1 text-white mt-3">
            คลิกเลย!
          </a>
        </div>
      </div>
    </div>

    <div id="myModal3" ref="myModal3" class="modal">
      <div class="modal-content text-end">
        <span id="close3" ref="close3" v-on:click="closeModal3()" class="close"
          >&times;</span
        >
        <div class="thankyou">
          <h1 class="text-theme-5">
            <span class="bgtext slogan"
              ><b>คุณได้ใช้สิทธิ์โหวตครบ 3 ภาพไปแล้ว</b></span
            >
          </h1>
          <h2 class="text-theme-5 mt-4">
            รอลุ้นเป็นผู้โชคดี รับ <b>“External Harddisk 240 GB”</b><br />
            <b>ประกาศผล 4 มี.ค. 65 ทาง Facebook และ LINE Thai PBS Family</b>
          </h2>
          <img src="../assets/qrcode.png" class="img3 w-50 pb-4" />
          <h2>เข้าใช้งาน Thai PBS Madia Stock ได้จากทุกที่</h2>
          <h3>
            <a href="https://mediastock.thaipbs.or.th" target="_blank"
              >mediastock.thaipbs.or.th</a
            >
          </h3>
          <br />
          <a href="/" class="btn btn-round bg-tpbs-1 text-white mt-3">
            กลับไปหน้าหลัก
          </a>
        </div>
      </div>
    </div>
    <div class="container mt-4">
      <div class="btn-back text-start">
        <a href="/" class="btn btn-clear">
          <i class="fas fa-chevron-left"></i>
          <span>&nbsp;ย้อนกลับ</span>
        </a>
      </div>
      <div class="img-preview">
        <a :href="image.src" data-lightbox="image-1" :data-title="image.title">
          <img :src="image.src" :alt="image.title" />
        </a>
      </div>
      <!--<h5 v-if="!isVoted" class="text-end text-tpbs-2">
        <i  class="far fa-thumbs-up"></i>
        <span class="ps-1 h3"
          ><b>{{ image.upvoted }}</b></span
        >
      </h5>
       <h5 v-if="isVoted" class="text-end text-tpbs-1">
        <i class="fas fa-thumbs-up"></i>
        <span class="ps-1 h3"
          ><b>{{ image.upvoted }}</b></span
        >
      </h5>-->
      <h4>
        <!--<span class="text-gray">■</span>-->
        ชื่อภาพ : {{ image.title }}
      </h4>
      <!--<h4>สร้างสรรค์โดย : {{ image.author }}</h4>-->

      <div class="text-start mt-4">
        <h3 class="text-center">
          <strong>โปรดกรอกข้อมูลอีกนิด ให้เราติดต่อคุณได้</strong>
        </h3>
        <div class="mb-3">
          <label for="fullNameInput" class="form-label">ชื่อ - นามสกุล</label>
          <input
            class="form-control"
            id="fullNameInput"
            aria-describedby="fullNameHelp"
            v-model="fullname"
            v-on:change="revalidateForm()"
          />
          <div
            v-if="warnings[0]"
            id="fullNameHelp"
            class="form-text text-danger"
          >
            *กรุณากรอกชื่อ-นามสกุลของท่าน
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="employeeIdInput" class="form-label"
                >รหัสพนักงาน</label
              >
              <input
                class="form-control"
                id="employeeIdInput"
                aria-describedby="employeeIdHelp"
                v-model="employeeId"
                v-on:change="revalidateForm()"
              />
              <div
                v-if="warnings[1]"
                id="employeeIdHelp"
                class="form-text text-danger"
              >
                *กรุณากรอกรหัสพนักงาน
              </div>
              <div
                v-if="warnings[3]"
                id="employeeIdHelp"
                class="form-text text-danger"
              >
                *รหัสพนักงานของคุณไม่ถูกต้อง
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="phoneInput" class="form-label">เบอร์ติดต่อ</label>
              <input
                type="tel"
                class="form-control"
                id="phoneInput"
                aria-describedby="phoneHelp"
                v-model="phone"
                v-on:change="revalidateForm()"
              />
              <div
                v-if="warnings[2]"
                id="phoneHelp"
                class="form-text text-danger"
              >
                *กรุณากรอกเบอร์ติดต่อ
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="reasonInput" class="form-label"
            >บอกเราหน่อย ทำไมคุณถึงโหวตให้ภาพนี้ ?</label
          >
          <input
            class="form-control"
            id="reasonInput"
            aria-describedby="reasonHelp"
            v-model="reason"
            v-on:change="revalidateForm()"
          />
          <div v-if="warnings[4]" id="reasonHelp" class="form-text text-danger">
            *กรุณาระบุเหตุผลที่คุณเลือกโหวตภาพนี้
          </div>
        </div>
        <div class="text-center mt-4 pt-4">
          <button
            v-on:click="submitForm()"
            class="btn btn-round bg-tpbs-1 text-white hover-zoom"
          >
            โหวตเลย !
          </button>
        </div>
      </div>
      <div class="container">
        <div class="mt-4 pt-3 mb-4">
          <div class="row">
            <div class="col-3 reward">
              <img src="../assets/extdisk.png" />
            </div>
            <div class="col">
              <p class="text-muted text-description">
                <span>
                  <h2 class="slogan text-tpbs-1 opacity-90">
                    <b
                      >ประกาศรางวัล Popular Vote<br />
                      ภาพขวัญใจชาวไทยพีบีเอส</b
                    >
                  </h2>
                </span>
                <span
                  ><b
                    >ในวันที่ 4 มี.ค. 65 ผ่านทาง Facebook และ LINE Thai PBS
                    Family</b
                  ></span
                >
                <span
                  ><h4 class="bgtext text-coal">
                    <b
                      >เจ้าของภาพและผู้ร่วมโหวตลุ้นรับ “External Harddisk 240
                      GB”</b
                    >
                  </h4></span
                >
                <span class="text-red">
                  <b>
                    *เงื่อนไข: พนักงานหนึ่งท่านโหวตได้ไม่เกิน 3 ภาพ
                    และต้องโหวตไม่ซ้ำภาพเดิม
                  </b>
                </span>
              </p>
            </div>
          </div>
          <div class="footer">
            <a
              class="pb-4"
              href="https://mediastock.thaipbs.or.th"
              target="_blank"
            >
              <img src="../assets/mediastock.png" class="img1" />
            </a>
            <img src="../assets/qrcode.png" class="img2 pb-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseConnect from "../environment/firebaseConnect";

export default {
  name: "ImageComp",
  props: {},
  data: function () {
    return {
      id: null,
      image: {},
      fullname: "",
      employeeId: "",
      phone: "",
      reason: "",
      warnings: [false, false, false, false, false],
      isVoted: false,
      votes: [],
      modal: null,
      span: null,
      modal2: null,
      span2: null,
      modal3: null,
      span3: null,
    };
  },
  created: function () {
    this.modal = document.querySelector("#myModal");
    this.span = document.querySelector("#close");
    this.modal2 = document.querySelector("#myModal2");
    this.span2 = document.querySelector("#close2");
    this.modal3 = document.querySelector("#myModal3");
    this.span3 = document.querySelector("#close3");

    window.onclick = function (event) {
      if (event.target == this.modal) {
        this.closeModal();
        this.closeModal2();
        this.closeModal3();
      }
    };

    this.votes = JSON.parse(localStorage.getItem("votes"));
    if (!this.votes) this.votes = [];

    const urlParams = new URLSearchParams(window.location.search);
    this.id = urlParams.get("id");
    if (this.id) {
      firebaseConnect
        .getImage(this.id)
        .then((item) => {
          document.title = item.title + " - Media Stock Popular Vote";
          this.image = item;
          this.votes.forEach((val) => {
            if (val == this.id) this.isVoted = true;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {
    closeModal: function () {
      this.modal = document.querySelector("#myModal");
      this.span = document.querySelector("#close");
      this.modal.style.display = "none";
    },
    showModal: function () {
      this.modal = document.querySelector("#myModal");
      this.span = document.querySelector("#close");
      this.modal.style.display = "block";
    },
    closeModal2: function () {
      this.modal2 = document.querySelector("#myModal2");
      this.span2 = document.querySelector("#close2");
      this.modal2.style.display = "none";
    },
    showModal2: function () {
      this.modal2 = document.querySelector("#myModal2");
      this.span2 = document.querySelector("#close2");
      this.modal2.style.display = "block";
    },
    closeModal3: function () {
      this.modal3 = document.querySelector("#myModal3");
      this.span3 = document.querySelector("#close3");
      this.modal3.style.display = "none";
    },
    showModal3: function () {
      this.modal3 = document.querySelector("#myModal3");
      this.span3 = document.querySelector("#close3");
      this.modal3.style.display = "block";
    },
    goToImage: function () {
      window.location.href = "/image";
    },
    submitForm: function () {
      if (this.revalidateForm()) {
        firebaseConnect
          .checkUserVoted(this.id, this.employeeId)
          .then((val) => {
            if (!val.isVoted) {
              firebaseConnect
                .vote(this.id, this.employeeId, this.fullname, this.phone, this.reason)
                .then((ok) => {
                  if (ok) {
                    this.votes.push(this.id);
                    localStorage.setItem("votes", JSON.stringify(this.votes));
                    this.isVoted = true;
                    this.image.upvoted++;
                    this.$forceUpdate(); //force re-render
                    this.showModal2();
                  } else {
                    this.showModal3();
                    window.location.href = "#";
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              firebaseConnect
                .revote(
                  val.docId,
                  this.id,
                  this.employeeId,
                  this.fullname,
                  this.phone,
                  this.reason
                )
                .then(() => {
                  this.showModal();
                  this.votes.push(this.id);
                  localStorage.setItem("votes", JSON.stringify(this.votes));
                  this.isVoted = true;
                  window.location.href = "#";
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        //console.log(this.warnings)
        //this.revalidateForm()
        /*if (this.phone == "") {
          this.warnings[2] = true;
          window.location.href = "#phoneHelp";
        }
        if (this.employeeId == "") {
          this.warnings[1] = true;
          window.location.href = "#employeeIdHelp";
        }
        if (this.fullname == "") {
          this.warnings[0] = true;
          window.location.href = "#fullNameHelp";
        }*/
        //this.$forceUpdate(); //force re-render
      }
    },
    revalidateForm: function () {
      let finale = true;
      if (this.reason == "") {
        this.warnings[4] = true;
        finale = false;
        window.location.href = "#reasonHelp";
      } else {
        this.warnings[0] = false;
      }
      if (this.phone == "") {
        this.warnings[2] = true;
        finale = false;
        window.location.href = "#phoneHelp";
      } else {
        this.warnings[2] = false;
      }
      if (this.employeeId == "") {
        this.warnings[1] = true;
        this.warnings[3] = false;
        finale = false;
        window.location.href = "#employeeIdHelp";
      } else {
        this.warnings[1] = false;
        //check employeeId format
        if (
          (this.employeeId[0] == "0" || this.employeeId[0] == "8") &&
          this.employeeId.length == 5
        ) {
          this.warnings[3] = false;
        } else if (this.employeeId[0] == "P" && this.employeeId.length == 6) {
          this.warnings[3] = false;
        } else {
          this.warnings[3] = true;
          window.location.href = "#employeeIdHelp";
          finale = false;
        }
      }
      if (this.fullname == "") {
        this.warnings[0] = true;
        finale = false;
        window.location.href = "#fullNameHelp";
      } else {
        this.warnings[0] = false;
      }
      this.$forceUpdate(); //force re-render
      return finale;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  margin-bottom: 0px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #444;
}
h1 {
  margin-top: 0px;
  font-weight: bold;
}

.thankyou {
  text-align: center;
}

.thankyou img {
  width: 28rem;
  height: auto;
  object-fit: contain;
}

@media only screen and (max-width: 629px) {
  .thankyou img {
    width: 16rem;
    height: auto;
    object-fit: contain;
  }
}

.img-preview img {
  /* 46:35 */
  height: auto;
}

.form-control {
  font-size: 20px;
}

.img3 {
  width: 256px;
  height: auto;
}
</style>
