<template>
  <div id="stats">
    <StatsComp />
  </div>
</template>

<script>
import StatsComp from "@/components/StatsComp.vue";

export default {
  name: "StatsView",
  components: {
    StatsComp,
  },
};
</script>