import Vue from 'vue'
import App from './App.vue'

import router from './router'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')