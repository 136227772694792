<template>
  <div id="thankyou" class="hello">
    <div class="p-4 mt-4">
      <h1>
        ผลโหวตรวมเดือนกุมภาพันธ์ 2565 (วันที่ 14-28): {{ totalVotes }} โหวต
      </h1>
      <div class="row">
        <div class="row">
          <canvas
            id="myChart"
            width="400"
            height="180"
            class="bg-white"
          ></canvas>
        </div>
        <div class="row mt-4">
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 14</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[0] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['14']">
                    จำนวนโหวต: <b>{{ item.votesByDate["14"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['14']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['14']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['14'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 15</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[1] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['15']">
                    จำนวนโหวต: <b>{{ item.votesByDate["15"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['15']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['15']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['15'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 16</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[2] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['16']">
                    จำนวนโหวต: <b>{{ item.votesByDate["16"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['16']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['16']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['16'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 17</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[3] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['17']">
                    จำนวนโหวต: <b>{{ item.votesByDate["17"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['17']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['17']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['17'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 18</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[4] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['18']">
                    จำนวนโหวต: <b>{{ item.votesByDate["18"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['18']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['18']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['18'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 19</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[5] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['19']">
                    จำนวนโหวต: <b>{{ item.votesByDate["19"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['19']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['19']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['19'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 20</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[6] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['20']">
                    จำนวนโหวต: <b>{{ item.votesByDate["20"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['20']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['20']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['20'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 21</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[7] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['21']">
                    จำนวนโหวต: <b>{{ item.votesByDate["21"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['21']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['21']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['21'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 22</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[8] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['22']">
                    จำนวนโหวต: <b>{{ item.votesByDate["22"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['22']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['15']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['15'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 23</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[9] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['23']">
                    จำนวนโหวต: <b>{{ item.votesByDate["23"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['23']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['23']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['23'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 24</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[10] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['24']">
                    จำนวนโหวต: <b>{{ item.votesByDate["24"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['24']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['24']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['24'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 25</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[11] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['25']">
                    จำนวนโหวต: <b>{{ item.votesByDate["25"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['25']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['25']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['25'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 26</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[12] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['26']">
                    จำนวนโหวต: <b>{{ item.votesByDate["26"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['26']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['26']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['26'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 27</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[13] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['27']">
                    จำนวนโหวต: <b>{{ item.votesByDate["27"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['27']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['27']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['27'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-3">
            <div class="card" style="width: 100%">
              <div class="card-body text-start">
                <h2 class="card-title"><b>วันที่ 28</b></h2>
                <h4 class="card-title">
                  ผลโหวตรวม: <b>{{ dates[14] }}</b>
                </h4>
              </div>
              <div
                v-for="item in contents"
                :key="item.id"
                class="mb-3 px-2 row text-start"
              >
                <div class="col">
                  <span class="text-muted"
                    ><small>{{ item.title }}</small></span
                  >
                  <h4 v-if="item.votesByDate['28']">
                    จำนวนโหวต: <b>{{ item.votesByDate["28"].count }}</b>
                  </h4>
                  <h4 v-if="!item.votesByDate['28']" class="text-muted">
                    จำนวนโหวต: <b>0</b>
                  </h4>
                  <div class="bg-tpbs-4 h-custom w-100">
                    <div
                      v-if="item.votesByDate['28']"
                      class="bg-tpbs-1 h-custom"
                      :style="displayWidth(item.votesByDate['28'].count)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div v-for="(item, index) in images" :key="item.id" class="p-4 mt-4">
      <div class="row">
        <div class="col-6 text-end">
          <div class="img-preview">
            <a :href="item.src" data-lightbox="image-1">
              <img :src="item.src" :alt="item.title" />
            </a>
          </div>
        </div>
        <div class="col-6 text-start">
          <h1>
            <!--<span class="text-gray">■</span>-->
            อันดับที่ {{ index + 1 }}
          </h1>
          <h2>
            <!--<span class="text-gray">■</span>-->
            ชื่อภาพ : {{ item.title }}
          </h2>
          <h2>สร้างสรรค์โดย : {{ item.author }}</h2>
          <h2>
            ผลโหวต : <b>{{ item.upvoted }}</b>
          </h2>
        </div>
      </div>

      <div class="row mt-4">
        <h2>ผู้โหวตทั้งหมด {{ item.upvoted }} ท่าน</h2>
        <div v-for="voter in item.voters" :key="voter.id" class="col-3 mb-3">
          <div class="card" style="width: 100%">
            <div class="card-body text-start">
              <h5 class="card-title text-muted">
                <b>รหัสพนักงาน:</b> {{ voter.employeeId }}
              </h5>
              <h4>
                <b>{{ voter.fullname }}</b>
              </h4>
            </div>
            <ul class="list-group list-group-flush text-start">
              <li class="list-group-item text-muted">
                <small></small><br />
                <small><b>เบอร์โทร:</b> {{ voter.phone }}</small
                ><br />
                <small><b>เหตุผล:</b> {{ voter.reason }}</small
                ><br />
                <small><b>เมื่อวัน/เวลา:</b> {{ voter.date.toDate() }}</small>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import firebaseConnect from "../environment/firebaseConnect";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

export default {
  name: "StatsComp",
  props: {},
  data: function () {
    return {
      id: null,
      images: [],
      totalVotes: 0,
      contents: [],
      dates: [],
    };
  },
  created: function () {
    firebaseConnect
      .reports()
      .then((val) => {
        console.log(val);
        this.totalVotes = val.totalVote;
        this.contents = val.content;

        var dates = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (let i = 0; i < this.contents.length; i++) {
          const image = this.contents[i];
          for (const key in image.votesByDate) {
            if (key > 13 && key < 29) {
              dates[key - 14] += image.votesByDate[key].count;
            }
          }
        }

        this.dates = dates;

        const ctx = document.getElementById("myChart").getContext("2d");
        const myChart = new Chart(ctx, {
          type: "line",
          data: {
            labels: [
              "วันที่ 14",
              "วันที่ 15",
              "วันที่ 16",
              "วันที่ 17",
              "วันที่ 18",
              "วันที่ 19",
              "วันที่ 20",
              "วันที่ 21",
              "วันที่ 22",
              "วันที่ 23",
              "วันที่ 24",
              "วันที่ 25",
              "วันที่ 26",
              "วันที่ 27",
              "วันที่ 28",
            ],
            datasets: [
              {
                label: "# of Votes",
                data: this.dates,
                backgroundColor: ["#fe5000"],
                borderColor: ["#fe5000"],
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
    firebaseConnect
      .getPopularVotes(3)
      .then((vals) => {
        console.log(vals);
        this.images = vals;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted: function () {},
  methods: {
    displayWidth: function (int) {
      return "width: " + int + "%";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  margin-bottom: 0px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #444;
}
h1 {
  margin-top: 0px;
  font-weight: bold;
}

.thankyou {
  text-align: center;
}

.thankyou img {
  width: 28rem;
  height: auto;
  object-fit: contain;
}

@media only screen and (max-width: 629px) {
  .thankyou img {
    width: 16rem;
    height: auto;
    object-fit: contain;
  }
}

.img-preview img {
  /* 46:35 */
  height: auto;
}

.form-control {
  font-size: 20px;
}

.img3 {
  width: 256px;
  height: auto;
}

img {
  width: 24rem;
  height: auto;
  object-fit: contain;
}

.w-custom {
  width: 80px;
  height: 100px;
  object-fit: cover;
}

.h-custom {
  height: 4px;
  padding-left: 0px;
  padding-right: 0px;
}

#myChart {
  border-radius: 16px;
}
</style>
