// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, getDoc, collection, query, where, orderBy, doc, addDoc, updateDoc, limit } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import axios from 'axios'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB8LReCXWSV10kkshWb07h4uOLLDgwfQy0",
  authDomain: "thai-pbs-special-project.firebaseapp.com",
  databaseURL: "https://thai-pbs-special-project-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "thai-pbs-special-project",
  storageBucket: "thai-pbs-special-project.appspot.com",
  messagingSenderId: "257657633521",
  appId: "1:257657633521:web:7d8824fb3ed64d01ec8750"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

const imageRef = collection(db, 'mediastock_images')
const popularvoteRef = collection(db, 'mediastock_popularvotes')

export default {
    firebaseApp: app,
    analytics: analytics,
    logEvent: logEvent,
    checkUserVoted: async function(imageId, employeeId){
      try {
        const q = query(popularvoteRef, where("imageId", '==', imageId),where("employeeId", "==", employeeId));
        const querySnapshot = await getDocs(q);
        return {
          isVoted: !querySnapshot.empty,
          docId: querySnapshot.docs[0].id
        }
      } catch (error) {
        return error
      }
    },
    vote: async function(imageId, employeeId, fullname, phone, reason){
      try {
        var q = query(popularvoteRef ,where('employeeId', '==', employeeId))
        var docs = await getDocs(q)
        if(docs.docs.length < 3){
          const data = {
            date: new Date(),
            imageId: imageId,
            employeeId: employeeId,
            fullname: fullname,
            phone: phone,
            reason: reason
          }
  
          await addDoc(popularvoteRef, data)
          const docRef = doc(imageRef, imageId)
          const docSnapshot = await getDoc(docRef);
          var upvoted = docSnapshot.data().upvoted
          upvoted++
          await updateDoc(docRef, {upvoted: upvoted})
          return true
        }else{
          return false
        }
      } catch (error) {
        return error
      }
    },
    revote: async function(docId, imageId, employeeId, fullname, phone, reason){
      try {
        const data = {
          date: new Date(),
          imageId: imageId,
          employeeId: employeeId,
          fullname: fullname,
          phone: phone,
          reason: reason
        }
        const docRef0 = doc(popularvoteRef, docId)
        await updateDoc(docRef0, data)
        return true
      } catch (error) {
        return error
      }
    },
    getImage: async function(imageId){
      try {
        const docRef = doc(imageRef, imageId)
        const docSnapshot = await getDoc(docRef);
        var data = docSnapshot.data()
        data.id = docSnapshot.id
        return data
      } catch (error) {
        return error
      }
    },
    getAllImages: async function(){
      try {
        const q = query(imageRef, orderBy('ord', 'asc'));
        const querySnapshot = await getDocs(q);
        var arr = []
        await querySnapshot.docs.forEach(val => {
          var d = val.data()
          d.id = val.id
          arr.push(d)
        })
        return arr
      } catch (error) {
        return error
      }
    },
    getPopularVotes: async function(lim){
      try {
        const q = query(imageRef, orderBy('upvoted', 'desc'), limit(lim));
        const querySnapshot = await getDocs(q);
        var arr = []
        for (let i = 0; i < querySnapshot.docs.length; i++) {
          const val = querySnapshot.docs[i];
          var d = val.data()
          d.id = val.id

          var voters = []
          const q2 = query(popularvoteRef, where("imageId", '==', d.id), orderBy('date', 'desc'));
          const querySnapshot2 = await getDocs(q2);
          for (let i2 = 0; i2 < querySnapshot2.docs.length; i2++) {
            const val2 = querySnapshot2.docs[i2];
            var d2 = val2.data()
            d2.id = val2.id
            voters.push(d2)
          }
          d.voters = voters
          
          arr.push(d)
        }

        return arr
      } catch (error) {
        return error
      }
    },
    reports: function(){
      return new Promise((resolve, reject) => {
        var config = {
          method: 'get',
          url: 'https://asia-southeast1-thai-pbs-special-project.cloudfunctions.net/wordcloud/mediastock-votes',
          headers: { }
        };
        
        axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
      })
    }

}