<template>
  <div id="home">
    <div class="slogan">
    <a href="/"><img class="logo" alt="Mediastock logo" src="../assets/logo.png" /></a>
    </div>
    <Welcome  />
  </div>
</template>

<script>
import Welcome from "../components/Welcome.vue";

export default {
  name: "Home",
  components: {
    Welcome,
  },
};
</script>