<template>
  <div class="hello">
    <h4 class="mb-0 opacity-90 slogan">
      คุณอยากให้ภาพใดใน “#15MemoryShots” เป็น
    </h4>
    <h2 class="text-theme-2 opacity-90 slogan">
      Popular Vote ภาพขวัญใจชาวไทยพีบีเอส
    </h2>
    <div class="hilight">
      <img
        class="obj-scaling"
        alt="คลิกโหวตที่ภาพเลย !!!"
        src="../assets/hilight.png"
      />
    </div>
    <div class="mt-4 container container2">
      <div class="row">
        <div
          v-for="item in images"
          :key="item.ord"
          class="col-md-4 col-12 mb-2"
        >
          <div class="row">
            <div class="col-md-12 col-5">
              <div v-on:click="goToImage(item.id)" class="img-preview">
                <img :src="item.src" class="hover-zoom" :alt="item.title" />
                <!--<div v-if="!item.isVoted" class="reaction bg-white text-tpbs-2">
              <i class="far fa-thumbs-up"></i>
              <span class="ps-1">{{item.upvoted}}</span>
            </div>
            <div v-if="item.isVoted" class="reaction bg-tpbs-1 text-white">
              <i class="fas fa-thumbs-up"></i>
              <span class="ps-1">{{item.upvoted}}</span>
            </div>-->
              </div>
            </div>
            <div class="col-md-12 col-7">
              <h4 class="card-text a">
                <!--<span class="text-gray">■</span>-->
                ชื่อภาพ : {{ item.title }}
              </h4>
              <!--<h4 class="card-text">สร้างสรรค์โดย : {{ item.author }}</h4>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="mt-4 pt-3 mb-4">
        <div class="row">
          <div class="col-3 reward">
            <img src="../assets/extdisk.png" />
          </div>
          <div class="col">
            <p class="text-muted text-description">
              <span>
                <h2 class="slogan text-tpbs-1 opacity-90">
                  <b
                    >ประกาศรางวัล Popular Vote<br />
                    ภาพขวัญใจชาวไทยพีบีเอส</b
                  >
                </h2>
              </span>
              <span
                ><b
                  >ในวันที่ 4 มี.ค. 65 ผ่านทาง Facebook และ LINE Thai PBS
                  Family</b
                ></span
              >
              <span
                ><h4 class="bgtext text-coal">
                  <b
                    >เจ้าของภาพและผู้ร่วมโหวตลุ้นรับ “External Harddisk 240
                    GB”</b
                  >
                </h4></span
              >
              <span class="text-red">
                <b>
                *เงื่อนไข: พนักงานหนึ่งท่านโหวตได้ไม่เกิน 3 ภาพ และต้องโหวตไม่ซ้ำภาพเดิม
                </b>
              </span>
            </p>
          </div>
        </div>
        <div class="footer">
          <a
            class="pb-4"
            href="https://mediastock.thaipbs.or.th"
            target="_blank"
          >
            <img src="../assets/mediastock.png" class="img1" />
          </a>
          <img src="../assets/qrcode.png" class="img2 pb-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseConnect from "../environment/firebaseConnect";

export default {
  name: "Welcome",
  props: {},
  data: function () {
    return {
      images: [],
      votes: [],
    };
  },
  created: function () {
    document.title = "MediaStock Popular Vote";

    this.votes = JSON.parse(localStorage.getItem("votes"));
    if (!this.votes) this.votes = [];
    firebaseConnect
      .getAllImages()
      .then((items) => {
        this.images = items;

        this.images.forEach((val) => {
          this.votes.forEach((val2) => {
            if (val.id == val2) {
              val.isVoted = true;
            }
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    goToImage: function (id) {
      window.location.href = "/image?id=" + id;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  margin-bottom: 0px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
h1 {
  margin-top: 0px;
  font-weight: bold;
}

.hilight {
  text-align: center;
}

.hilight img {
  width: 20rem;
  height: auto;
  object-fit: contain;
}

@media only screen and (max-width: 629px) {
  .hilight img {
    width: 16rem;
    height: auto;
    object-fit: contain;
  }

  .hilight {
    text-align: left;
    padding-left: 32px;
  }

  .description {
  }

  .img-preview img {
    height: 133px;
  }

  .card-text {
    font-family: DBHelvethaicaXMedCond;
  }

  .a {
    margin-top: 30px;
  }

  .col-6 {
    padding-right: 2px;
    padding-left: 2px;
  }

  .col-5 {
    padding-right: 4px;
    padding-left: 4px;
  }

  .col-7 {
    padding-right: 4px;
    padding-left: 4px;
  }
}

@media only screen and (min-width: 630px) {
}
</style>
